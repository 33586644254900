@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

:root {
  --height-web-header: 64px;
}

.hd_header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding: 0 24px;
  width: 100%;
  height: var(--height-web-header);
  min-height: var(--height-web-header);
  justify-content: space-between;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: calc(100% - 48px);
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    height: 1px;
    background: radial-gradient(50% 72001726.04% at 50% 51.5%, rgba(32, 31, 30, 0.25) 0%, rgba(32, 31, 30, 0) 100%);
  }

  .traffic-lights {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    .traffic-lights-btns-wrap {
      height: 48px;

      button {
        height: 48px;
      }
    }
  }

  &__logo {

  }
  &__icon {
    width: 32px;
    height: 32px;
  }
  &__heading {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: var(--text-left-100);
    @include is-mobile() {
      color: var(--text-100);
    }
    & > span {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--text-left-80);
      @include is-mobile() {
        color: var(--text-80);
      }
    }
  }

  @include is-mobile() {
    padding: 0;
    &__heading {
      & > span {
        display: none;
      }
    }
  }
}



.is-electron-auth {
  .hd_header {
    -webkit-app-region: drag;

    > div, > button {
      -webkit-app-region: no-drag;
    }
  }

  &-win {
    .hd_header {
      > button {
        margin-right: 122px;
      }
    }
  }

  &-mac:not(.is-full-screen) {
    .hd_header {
      > .layout {
        margin-left: 61px;
      }
    }
  }
}

.in-iframe {
  .hd_header {
    display: none;
  }

  .p_main {
    padding-top: 24px;
  }
}


