@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';

.inp_wrapper {
  display: flex;
  flex-direction: column;
}

.inp_auth_input {
  .header-left .component-text {
    color: var(--text-left-80);
    @include is-mobile() {
      color: var(--text-80);
    }
  }

  .form-field__description {
    color: var(--text-left-80);
    @include is-mobile() {
      color: var(--text-80);
    }
  }

  @include is-mobile() {
    .form-item {
      background: var(--bg-second);
      border-radius: 12px;
      height: 44px;
    }

    input {
      height: 44px;
    }

    .form-field__description {
      padding: 0 8px;
    }
  }

  @include  apply-styles-for-themes(3, true) {
    .form-item {
      background-color: var(--bg-body);
      input {
        background-color: var(--bg-body);
      }
    }
  }
}

.inp_title {
  margin-bottom: 7px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-left-80);

  @include is-mobile() {
    color: var(--text-100);
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    padding:0 8px;
  }
}

//.inp_input {
//  padding: 10px 12px;
//  background: var(--bg-second);
//  border: 1px solid;
//  border-color: var(--stroke-opacity);
//  border-radius: 4px;
//  font-weight: 400;
//  font-size: 15px;
//  line-height: 20px;
//  color: var(--text-100);
//  transition: border-color 0.2s ease;
//  &::placeholder {
//    @include theme-aware('color', 'text-60');
//  }
//}

.inp_error_message {
  margin: 4px 0 0 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $error;
}

//.inp_is_focused {
//  border: 1px solid $action;
//}

.inp_full_width {
  min-width: 100%;
}

.inp_error {
  border: 1px solid $error;
}
