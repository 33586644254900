@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';

.j_join {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 520px;
  &__avatar {
    margin-bottom: 24px;
  }
  &__bottom_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 400px;
  }
  &__input {
    margin-bottom: 16px;

    & .form-field__error {
      color: var(--text-left-80);
      @include is-mobile() {
        color: var(--text-80);
      }
    }
  }

  @include is-mobile() {
    width: 100%;
    &__bottom_wrapper {
      width: 100%;
    }
  }
}

.j_company_name {
  color: var(--text-left-100);
  @include is-mobile() {
    color: var(--text-100);
  }
}


