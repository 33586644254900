@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';

.mobile-header {
  width: 100%;
  justify-content: space-between;

  &.component-dialog-title {

    @include is-mobile() {
      min-height: var(--android-mobile-header-height);
    }

    @include is-ios() {
      min-height: var(--ios-mobile-header-height);
    }

    padding-left: 4px;
    padding-right: 4px;
  }


  .component-dialog-title-title-text {
    font-size: 18px;
  }

  .component-dialog-title-title {
    margin-right: 0;
  }

  .component-dialog-title-buttons {
    width: 40px;
    position: relative !important;
  }
}

.mobile-header_hide-btn {
  .toggle-left {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}