@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/variables';

.log_login {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  position: relative;

  &__logo {
    margin-bottom: 24px;
  }

  &__bottom_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 400px;
  }

  &__error {
    margin: 0 0 16px 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: $error;
    animation-duration: 0.1s;
    animation-timing-function: ease;
    animation-name: expanding;

    @include is-mobile() {
      font-size: 17px;
    }
  }

  &__input {
    margin-bottom: 16px;
  }

  @include is-mobile() {
    width: 100%;
    &__bottom_container {
      width: 100%;
    }
  }
}

.log_login_header {
  padding: 0 0 24px 0;
  margin: 0 0 24px 0;
}

@keyframes expanding {
  from {
    max-height: 0;
  }
  to {
    max-height: auto;
  }
}

.log_body {
  width: 100%;
  padding: 24px 16px 0;
  flex-grow: 1;

  .tags-row-tag.new-mobile {
    max-width: 100% !important;
  }

  .component-tags-row-focus {
    min-height: auto !important;

    input {
      min-height: 30px;
    }
  }

  .log_body_input_error_container {
    @include is-mobile() {
      margin-top: 20px;
    }

    svg {
      color: var(--red-1);
    }
  }
}

.log_footer {
  padding: 16px;
  gap: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .log_privacy {
    padding-top: 0;
  }
}

.log_body_description {
  margin-top: 4px;

  span {
    padding: 0 8px;
    font: $footnote;
    color: var(--text-80)
  }
}

.log_privacy {
  &.log_privacy_keyboard_active {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
  }
}

.active-mobile-root {
  background-color: transparent !important;
}