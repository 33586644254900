@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';

.button-ui {
  @include is-mobile() {
     border-radius: 12px !important;

    &::before {
      border-radius: 12px !important;
    }

    .btn__title {
      font-size: 17px;
      line-height: 22px;
      font-weight: 700;
    }
  }
}