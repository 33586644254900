@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';

.hdi_container {
  display: flex;
  flex-direction: column;
  max-width: 520px;
  width: 100%;
  padding: 0 0 24px 0;
  margin: 0 0 24px 0;
  border-bottom: 1px solid;
  border-color: var(--stroke-opacity);

  @include is-mobile() {
    border: unset;
    padding: 0;
    margin: 0;
  }

  &_center {
    align-items: center;

    .hdi_title, .hdi_description {
      text-align: center;
    }
  }
  &_right {
    align-items: end;

    .hdi_title, .hdi_description {
      text-align: right;
    }
  }
  &_left {
    align-items: start;

    .hdi_title, .hdi_description {
      text-align: left;
    }
  }
}
.hdi_title {
  margin: 0 0 16px 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: var(--text-left-100);
  @include is-mobile() {
    color: var(--text-100);
  }
}
.hdi_title_nodescription {
  margin-bottom: 0;
}
.hdi_description {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: var(--text-left-80);
  @include is-mobile() {
    color: var(--text-80);
  }
}


