@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  src: local("☺"), url('../node_modules/@clout-team/web-components/build/assets/styles/fonts/lato-light-0b50f74.woff2') format("woff2"), url('../node_modules/@clout-team/web-components/build/assets/styles/fonts/lato-light-1475c14.woff') format("woff");
  unicode-range: U+0000-f8fe, U+f900-ffff;
  font-display: block;
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: local("☺"), url('../node_modules/@clout-team/web-components/build/assets/styles/fonts/lato-regular-d9ce515.woff2') format("woff2"), url('../node_modules/@clout-team/web-components/build/assets/styles/fonts/lato-regular-ea6d1e4.woff') format("woff");
  unicode-range: U+0000-f8fe, U+f900-ffff;
  font-display: block;
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src: local("☺"), url('../node_modules/@clout-team/web-components/build/assets/styles/fonts/lato-bold-4b1dc11.woff2') format("woff2"), url('../node_modules/@clout-team/web-components/build/assets/styles/fonts/lato-bold-a532381.woff') format("woff");
  unicode-range: U+0000-f8fe, U+f900-ffff;
  font-display: block;
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  src: local("☺"), url('../node_modules/@clout-team/web-components/build/assets/styles/fonts/lato-black-b64f5e4.woff2') format("woff2"), url('../node_modules/@clout-team/web-components/build/assets/styles/fonts/lato-black-8857ce8.woff') format("woff");
  unicode-range: U+0000-f8fe, U+f900-ffff;
  font-display: block;
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 300;
  src: local("☺"), url('../node_modules/@clout-team/web-components/build/assets/styles/fonts/lato-italic-light-c593311.woff2') format("woff2"), url('../node_modules/@clout-team/web-components/build/assets/styles/fonts/lato-italic-light-354fb58.woff') format("woff");
  unicode-range: U+0000-2259, U+2261-f8fe, U+f900-ffff;
  font-display: block;
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 400;
  src: local("☺"), url('../node_modules/@clout-team/web-components/build/assets/styles/fonts/lato-italic-5bbcd6c.woff2') format("woff2"), url('../node_modules/@clout-team/web-components/build/assets/styles/fonts/lato-italic-d5261ac.woff') format("woff");
  unicode-range: U+0000-2259, U+2261-f8fe, U+f900-ffff;
  font-display: block;
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 700;
  src: local("☺"), url('../node_modules/@clout-team/web-components/build/assets/styles/fonts/lato-italic-bold-99def7d.woff2') format("woff2"), url('../node_modules/@clout-team/web-components/build/assets/styles/fonts/lato-italic-bold-2187a11.woff') format("woff");
  unicode-range: U+0000-2259, U+2261-f8fe, U+f900-ffff;
  font-display: block;
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 900;
  src: local("☺"), url('../node_modules/@clout-team/web-components/build/assets/styles/fonts/lato-italic-black-04aca50.woff2') format("woff2"), url('../node_modules/@clout-team/web-components/build/assets/styles/fonts/lato-italic-black-23c1971.woff') format("woff");
  unicode-range: U+0000-2259, U+2261-f8fe, U+f900-ffff;
  font-display: block;
}
