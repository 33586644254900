@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';


.ws_workspace {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  width: 100%;

  &_header {
    @include is-mobile() {
      padding: 4px 8px;
    }
  }

  &_label {
    width: 100%;
    padding: 12px 16px;
    font: $body-mobile;
    color: var(--text-80);
    text-align: left;
    background: var(--bg-second);
  }

  &__actions {
    @include is-mobile() {
      .list-item {
        .list-item__icon-main-wrapper {
          width: 48px;
          height: 48px;
          border-radius: 12px;
          background-color: var(--bg-second);
          margin-right: 16px;
        }
      }
    }
  }

  & .mobile-header {
    background: var(--bg-body);
  }

  &__accounts_wrapper {
    @include is-not-mobile() {
     background-color: var(--bg-second);
    }
    padding: 8px 0;
    border-radius: 12px;
  }

  &__top {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 520px;
    padding: 0 0 24px 0;
    margin: 0 0 27px 0;
    border-bottom: 1px solid;
    border-color: var(--stroke-opacity);
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: var(--text-100);
  }

  &__accounts {
    padding: 0;
    width: 400px;
    border-radius: 6px;

    & .list-item {
      @include is-mobile() {
          .list-item__avatar {
            width: 48px;
            height: 48px;
            margin-right: 16px;
            .avatar__img-wrapper {
              border-radius: 12px;
            }
          }

        .list-item__title {
          font-size: 17px;
          font-weight: 700;
          line-height: 20px;
        }

        .list-item__info-text {
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
        }



        button {
          border: none;
          border-radius: 8px;

          &:not(.btn--action) {
            background-color: var(--surface-1);

            .btn__title {
              color: var(--text-80);
            }
          }
        }
      }
    }

    & + .ws_workspace__accounts {
      margin-top: 16px;
    }

    &_invite-resume {
      span {
        font-weight: 600;
      }
    }

    &_new_item {

      .list-item__right {
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    &_title {
      padding-left: 16px;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: var(--text-80);

      display: inline-flex;
      align-items: center;
      white-space: pre-wrap;

      & > span {
        font-weight: 600;
      }

      svg {
        width: 16px;
        min-width: 16px;
        height: 16px;
        margin-right: 4px;

        path {
          color: var(--text-80);
        }
      }

      @include is-mobile() {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: var(--text-100);
      }
    }

    &_extra_btns {
      padding: 8px 0;
      background-color: var(--bg-second);
      border-radius: 4px;
    }

    @include is-mobile() {
      background: var(--bg-body);
    }
  }

  &__text {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    @include is-not-mobile() {
      color: var(--text-left-80);
    }
  }

  @include is-mobile() {
    &__accounts {
      width: 100%;
    }

    background: var(--bg-second);
  }
}

