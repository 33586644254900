@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';


.cd_code {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;

  .cd_code_form_title {
    margin-bottom: 0;
    padding-bottom: 32px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 400px;
    .code-input__field {
      background-color: var(--bg-second);
    }
    &_description {
      margin-bottom: 18px;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: var(--text-left-80);
      @include is-mobile() {
        color: var(--text-80);
      }
    }
    &_buttons {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 16px;
      margin-bottom: 58px;
      width: 100%;
    }
  }

  &__error {
    margin: 16px 0 0 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--text-left-100);

    @include is-mobile() {
      font: $footnote-mobile;
      color: var(--red-1);
      margin-top: 32px;
    }
  }

  &__time_for_repeat {
    text-align: center;
    font: $body;
    color: var(--text-80);
    margin-top: 32px;
  }

  &__resend_btn {
    margin-bottom: 18px;
  }
  &__link {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: var(--text-left-100);
    @include is-mobile() {
      color: var(--text-100);
    }
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  @include is-mobile() {
    &__bottom {
      width: 100%;
    }
  }
}



.cd_code__resend_btn {
  & .btn__title {
    color: var(--text-left-80);
    @include is-mobile() {
      color: var(--text-80);
    }
  }
}

.cd_code_email_bg {
  padding: 20px 18px 19px;
  border-radius: 21px;
  background: var(--bg-second);
  margin-top: -16px;
  margin-bottom: 8px;
}

.cd_code_form {
  width: 100%;
  flex-grow: 1;
  padding: 24px 24px 0;
}

.cd_code_fields {

  .code-input__label {
    margin-right: 12px;

    input {
      border-radius: 12px;
      background: var(--bg-second);
      border: none;
      font-size: 40px;
    }

    @media only screen and (max-device-width: 360px) {
      margin-right: 6px;
    }
    @media only screen and (max-device-width: 320px) {
      margin-right: 2px;

      input {
        width: 30px;
        height: 38px;
        font-size: 22px;
      }
    }
  }
}

.cd_code_footer {
  width: 100%;
  padding: 16px;

  & > button {
    transition: color 0.3s ease, background-color 0.3s ease;
  }
}