@import '../../../node_modules/@clout-team/web-components/build/assets/styles/variables';

.component-page-redirect {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  padding-top: 48px;
  padding-left: 24px;
  padding-right: 24px;

  gap: 48px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  background: var(--bg-second);
  z-index: 9999999999;

  & .body-text {
    font-family: $font-family--primary;
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
    text-align: center;

    width: 100%;
    display: flex;
  }
  & .body-text-lenza {
    font-family: $font-family--primary;
    font-size: 20px;
    font-weight: 900;
    line-height: 26px;
    text-align: center;

    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;

    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 24px;
  }
  & .avatars-overlap {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
  }
  & .avatars-icons {
    position: relative;
    width: calc(100vw - 48px);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;

    & .ic {
      &.viewblock {
        overflow: hidden;
        border-radius: 32px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.1474), 0 32px 64px 0 rgba(0, 0, 0, 0.1876), 0 0 0 1px rgba(32, 31, 30, 0.12) inset;
      }
      &.line {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;

        gap: 24px;

        &:nth-child(1) {
          position: absolute;
          top: 0;
          transform: translateY(calc(-200% - 48px));
        }
        &:nth-child(2) {
          position: absolute;
          top: 0;
          transform: translateY(calc(-100% - 24px));
        }
      }
      &-120 {
        width: 120px;
        height: 120px;
        min-height: 120px;
        min-width: 120px;
      }
      &-96 {
        width: 96px;
        height: 96px;
        min-height: 96px;
        min-width: 96px;
      }
    }
  }
  & > .component-text {

  }
  & .device-img {
    height: 48px;
  }
  & .items {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 44px;

    font: $body-mobile;
  }
}