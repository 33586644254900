@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';

.cp_wrapper {
  width: 100%;
}

.cp_copy {
  display: inline-flex;
  position: relative;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--text-left-80);
  @include is-mobile() {
    color: var(--text-80);
  }
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s ease;
  margin-top: 16px;
  &:after {
    position: absolute;
    transform-origin: 0 50%;
    content: '';
    width: 100%;
    left: 0;
    bottom: 0;
    border-bottom: 1px solid var(--text-left-80);
    @include is-mobile() {
      border-bottom-color: var(--text-80);
    }
    transform: scaleX(0);
    transition: transform 0.2s ease;
  }
  &:hover:after {
    transform: scaleX(1);
  }
  &:active {
    opacity: 0.5;
  }
  &__icon {
    margin-right: 4px;
    width: 20px;
    height: 20px;
    fill: var(--text-100);
  }
}

.cp_popup {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--text-100);
  padding: 18px;
  border-radius: 4px;
  background-color: var(--bg-body);
  backdrop-filter: blur(30px);
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-name: appearance;
  &__icon {
    margin-right: 8px;
  }
}

.cp_popup_unmount {
  animation-name: unmount;
}

@keyframes appearance {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes unmount {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
