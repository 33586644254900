@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';

.ch_check {
  padding: 16px;
  background: var(--bg-second);
  box-shadow: $ui-shadow-flyout;
  border-radius: 4px;

  &__top {
    display: flex;
    padding: 20px;
    align-items: center;
    flex-direction: column;
    margin-bottom: 16px;
    width: 100%;
    background: rgb(103, 30, 92);
    background: -moz-linear-gradient(109deg, rgba(103, 30, 92, 1) 0%, rgba(86, 67, 165, 1) 100%);
    background: -webkit-linear-gradient(109deg, rgba(103, 30, 92, 1) 0%, rgba(86, 67, 165, 1) 100%);
    background: linear-gradient(109deg, rgba(103, 30, 92, 1) 0%, rgba(86, 67, 165, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#671e5c",endColorstr="#5643a5",GradientType=1);
    border-radius: 4px;
    &_description {
      position: relative;
      margin: 0;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: $white;
    }
  }
  // &__emoji {
  // }
  &__bot {
    display: flex;
    align-items: center;
    &_label {
      margin-bottom: 2px;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: var(--text-80);
    }
    &_description {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: var(--text-100);
      & > span {
        position: relative;
        color: $action;
        cursor: pointer;
        white-space: nowrap;
        opacity: 1;
        transition: opacity 0.2s ease;
        &:after {
          position: absolute;
          transform-origin: 0 50%;
          content: '';
          width: 85px;
          left: 0;
          bottom: 0;
          border-bottom: 1px solid var(--action);
          transform: scaleX(0);
          transition: transform 0.2s ease;
        }
        &:hover:after {
          transform: scaleX(1);
        }
        &:active {
          opacity: 0.5;
        }
      }
    }
    &_tooltip {
      position: absolute !important;
      left: -20px;
      bottom: -40px;
    }
  }
  &__checkbox {
    margin: 0 20px 0 0;
    padding: 0 0 0 4px;
  }
}
