@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';

.jd_join {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 520px;
  &__avatar {
    margin-bottom: 24px;
  }
  &__bottom_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 400px;
  }
  &__input {
    margin-right: 16px;
    max-width: calc(50% - 16px);
  }
  &__input_wrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px;
  }
  &__container {
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 6px;
    border: 1px solid #dbdbdb;
    background-color: #ebebeb;

    &_heading {
      margin: 0;
      font-weight: 600;
      font-size: 15px;
    }
    &_description {
      font-size: 13px;
      line-height: 18px;
      color: #605e5c;
      & > span {
        font-weight: 700;
      }
    }
  }

  @include is-mobile() {
    width: 100%;
    &__bottom_wrapper {
      width: 100%;
    }

    &__input_wrapper {
      flex-direction: column;
      width: 100%;
    }

    &__input {
      max-width: 100%;
      margin: 0 0 16px;
      width: 100%;
    }
  }
}

.jd_company_name {
  color: #407ec9;
}


