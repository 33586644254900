@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';

.l_loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;

  @include is-mobile() {
    min-height: 100%;
  }

  &__icon {
    margin-bottom: 24px;
    width: 81px;
    height: 62px;

    @include is-mobile() {
      width: 120px;
      height: 90px;
      margin-bottom: 30px;
      animation-name: size-icon;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }

  &__heading {
    margin: 0 0 8px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: var(--text-left-100);
    @include is-mobile() {
      color: var(--text-100);
      margin-bottom: 16px;
    }
  }
  &__description {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    @include is-mobile() {
      user-select: none;
    }
    color: var(--text-left-80);
    @include is-mobile() {
      color: var(--text-80);
    }
  }
}


@keyframes size-icon {
  from {
    scale: 1;
  }

  to {
    scale: 0.5;
  }
}