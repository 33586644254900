.country-flag {
  width: 20px;
  height: 20px;

  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  & img {
    width: 100%;
    height: auto;
  }
}
