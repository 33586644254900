@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.error-page {
  height: calc(100vh - var(--height-web-header));

  & .error-page-component {
    height: 100%;
  }

  @include is-mobile() {
    height: 100%;

    & .error-page-component {
      height: calc(100% - var(--android-mobile-header-height));
    }
  }

  @include is-ios() {
    & .error-page-component {
      height: calc(100% - var(--ios-mobile-header-height));
    }
  }
}