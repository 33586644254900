@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/variables';

.modal__description {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--text-100);
  margin-bottom: 16px;

  @include is-mobile() {
    padding: 8px 0;
    color: var(--text-80);
  }
}

.modal__form_title {
  margin: 0 0 7px 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-80);

  @include is-mobile() {
    font: $body-bold;
    color: var(--text-100);
  }
}


.send-first-message-for-friends {
  & .mobile-header {
    height: calc(var(--android-mobile-header-height) + var(--safe-area-top)) !important;
    min-height: calc(var(--android-mobile-header-height) + var(--safe-area-top)) !important;

    @include is-ios() {
      height: calc(var(--ios-mobile-header-height) + var(--safe-area-top)) !important;
      min-height: calc(var(--ios-mobile-header-height) + var(--safe-area-top)) !important;
    }
  }
}