@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.p_main {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  padding-bottom: 24px;
  overflow-y: auto;

  .form-item input, .component-tags-row-focus {
    background-color: var(--bg-second);
  }

  @include is-mobile() {
    padding: 0;
  }
}




