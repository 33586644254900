@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';


.inv_invalid {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/images/invalidBg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  &__heading {
    margin: 0 0 32px 0;
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
    color: var(--text-left-100);
    @include is-mobile() {
      color: var(--text-100);
    }
  }
  &__description {
    margin: 0 0 32px 0;
    max-width: 560px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: var(--text-left-80);
    @include is-mobile() {
      color: var(--text-80);
    }
  }
  &__button {
    padding: 10px 24px;
  }
}
